<template>
  <form-consulta
    :title="controller.titulo"
    @selecionado="selecionado"
    :controller="controller"
  />
</template>

<script>
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';

import FormConsulta from '@/views/consultas/FormConsulta';

export default {
  components: { FormConsulta },

  props: {
    controller: Object,
    formCadastro: {
      required: true,
    },
  },

  methods: {
    selecionado(selecionado) {
      const instancia = TFormUtil.instanciar(this.formCadastro);

      instancia.model.json = selecionado;
      TFormUtil.abrir(instancia);

      instancia.$on('aposGravar', registro => {
        this.atualizaListaRegistros(registro);
      });

      instancia.$on('aposExcluir', registro => {
        this.removeRegistro(registro);
      });
    },

    atualizaListaRegistros(novoRegistro) {
      const campoChave = this.controller.campoChave;
      if (!novoRegistro[campoChave]) return;

      this.controller.registros = this.controller.registros.map(registro => {
        if (Number(registro[campoChave]) === Number(novoRegistro[campoChave])) {
          return novoRegistro;
        }
        return registro;
      });
    },

    removeRegistro(registroExcluido) {
      const campoChave = this.controller.campoChave;
      if (!registroExcluido[campoChave]) return;

      this.controller.registros = this.controller.registros.filter(
        registro =>
          Number(registro[campoChave]) !== Number(registroExcluido[campoChave]),
      );
    },
  },
};
</script>
