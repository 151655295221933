<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaContato } from '@/proxy-api/src/services/classes/Contato/ConsultaContato';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormContato from '@/views/contato/form/FormContato';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaContato(),
      formCadastro: FormContato,
    };
  },
};
</script>
